<template>
    <v-form
        ref="editForm"
        v-model="validLocal"
        @submit="$emit('save',false)"
        @submit.prevent>
        <v-card>
            <v-card-title v-if="!hideTitle">
                <span class="headline">{{ mode }}{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <slot name="content" />
            </v-card-text>

            <v-card-actions>
                <v-btn
                    v-if="isEdit && !hideDelete"
                    color="quinary"
                    text
                    :disabled="isReadonly"
                    @click="$emit('deleteItem')">
                    Delete
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="!hideCancel"
                    color="quaternary"
                    text
                    @click="$emit('cancel')">
                    Cancel
                </v-btn>
                <v-btn
                    type="submit"
                    color="quaternary"
                    text
                    :disabled="isReadonly || isSaving">
                    Save
                </v-btn>
            </v-card-actions>
            <v-progress-linear v-if="isSaving" indeterminate color="blue" />
        </v-card>
    </v-form>
</template>

<script>
export default {
    props: {
        valid: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: null,
        },
        formTitle: {
            type: String,
            default: null,
        },
        isSaving: {
            type: Boolean,
            default: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideDelete: {
            type: Boolean,
            default: false,
        },
        hideCancel: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        validLocal: {
            get() {
                return this.valid;
            },
            set(valid) {
                this.$emit("update:valid", valid);
            },
        },
    },
};
</script>
